import React from 'react';
import { useAnalytics } from 'use-analytics';
import { useAuthState } from '../auth/context';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_TRACKING_ID } from '../../graphql/gql';
import { path } from 'ramda';

/**
 * Get unique trackingid based on userid
 */

const TrackUserID = () => {
  const analytics = useAnalytics();
  const authState = useAuthState();
  const [trackingId, setTrackingId] = React.useState();

  const [getTrackingId, { data }] = useLazyQuery(GET_USER_TRACKING_ID);

  React.useEffect(() => {
    if (!authState.isAuthenticated) {
      return;
    }

    const userData = path(['user'], data);

    if (!userData) {
      if (!authState.uid) return;
      // Get anonymous tracking id
      getTrackingId(authState.uid);
      return;
    } else if (userData) {
      // Assign anonymous trackingId to state
      const tid = path(['tracking', 'id'], userData);
      setTrackingId(tid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.uid, data]);

  // Pick up state changes to trackingId, will be triggered useEffect above if user is signed in
  React.useEffect(() => {
    if (trackingId) {
      analytics.identify(trackingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  return null;
};

export default React.memo(TrackUserID);
